import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  IMediaContainerSDK,
  IMediaContainerSDKFactory,
  MediaContainerCompProps,
} from '../MediaContainer.types';
import { backgroundPropsSDKFactory } from '../../../core/corvid/props-factories/backgroundPropsSDKFactory';
import { elementPropsSDKFactory } from '../../../core/corvid/props-factories/elementPropsSDKFactory';

export const sdk: IMediaContainerSDKFactory = composeSDKFactories<
  MediaContainerCompProps,
  IMediaContainerSDK
>(elementPropsSDKFactory, backgroundPropsSDKFactory);
