import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { EventHandler } from 'react';
import { withValidation } from '@wix/editor-elements-corvid-utils';

export interface IViewportPropSDK {
  onViewportEnter(cb: EventHandler<any>): void;
  onViewportLeave(cb: EventHandler<any>): void;
}

const _viewportPropsSDKFactory: CorvidSDKPropsFactory<
  {},
  IViewportPropSDK
> = () => {
  return {
    onViewportEnter: () => {
      throw new Error('onViewportEnter is not yet implemented'); // TODO - change this to real implementation
    },

    onViewportLeave: () => {
      throw new Error('onViewportLeave is not yet implemented'); // TODO - change this to real implementation
    },
  };
};

export const viewportPropsSDKFactory = withValidation(
  _viewportPropsSDKFactory,
  {
    type: ['object'],
    properties: {
      onViewportEnter: {
        type: ['function'],
        args: [{ type: ['function'] }],
      },
      onViewportLeave: {
        type: ['function'],
        args: [{ type: ['function'] }],
      },
    },
  },
);
