import { IFormContainerCorvidModel } from '../FormContainer.types';

const entry: IFormContainerCorvidModel = {
  componentType: 'FormContainer',
  loadSDK: () =>
    import(
      './FormContainer.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "FormContainer.corvid" */
    ),
};

export default entry;
