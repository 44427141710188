import { AccordionCorvidModel } from '../Accordion.types';

const entry: AccordionCorvidModel = {
  componentType: 'Accordion',
  loadSDK: () =>
    import(
      './Accordion.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "Accordion.corvid" */
    ),
};

export default entry;
