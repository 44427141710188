import * as React from 'react';
import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import {
  withValidation,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';

type KeyboardEventHandler = (e: React.KeyboardEvent) => void;
type FormEventHandler = (e: React.FormEvent) => void;

export interface ITextInputPropSDKProps {
  placeholder?: string;
  maxLength?: number | null;
}

export interface ITextInputPropSDK {
  placeholder: string;
  maxLength?: number | null;
  onKeyPress: (handler: KeyboardEventHandler) => void;
  onInput: (handler: FormEventHandler) => void;
}

export interface ITextInputPropsSDKActions<T = Element> {
  onKeyPress: (event: React.KeyboardEvent<T>) => void;
  onInput: (event: React.FormEvent<T>) => void;
}

const _textInputPropsSDKFactory: CorvidSDKPropsFactory<
  ITextInputPropSDKProps,
  ITextInputPropSDK
> = ({ registerEvent, setProps, props, metaData }) => {
  const schemaValidator = createCompSchemaValidator(metaData.role);

  return {
    onKeyPress(handler: KeyboardEventHandler) {
      const isValid = schemaValidator(
        handler,
        { type: ['function'] },
        'onKeyPress',
      );
      if (!isValid) {
        return;
      }
      registerEvent('onKeyPress', handler);
    },
    onInput(handler: FormEventHandler) {
      const isValid = schemaValidator(
        handler,
        { type: ['function'] },
        'onInput',
      );
      if (!isValid) {
        return;
      }
      registerEvent('onInput', handler);
    },
    get placeholder() {
      return props.placeholder || '';
    },
    set placeholder(value) {
      const placeholder = value || '';
      setProps({ placeholder });
    },
    get maxLength() {
      return props.maxLength;
    },
    set maxLength(value) {
      const maxLength = value === null || value === undefined ? null : value;
      setProps({ maxLength });
    },
  };
};

export const textInputPropsSDKFactory = withValidation(
  _textInputPropsSDKFactory,
  {
    type: ['object'],
    properties: {
      placeholder: {
        type: ['string', 'nil'],
        warnIfNil: true,
      },
      maxLength: {
        type: ['integer', 'nil'],
        warnIfNil: true,
        minimum: 0,
      },
    },
  },
);
