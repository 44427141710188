import { IRadioGroupCorvidModel } from '../RadioGroup.types';

const entry: IRadioGroupCorvidModel = {
  componentType: 'RadioGroup',
  loadSDK: () =>
    import(
      './RadioGroup.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "RadioGroup.corvid" */
    ),
};

export default entry;
