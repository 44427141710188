import { IStylableButtonCorvidModel } from '../StylableButton.types';

const entry: IStylableButtonCorvidModel = {
  componentType: 'StylableButton',
  loadSDK: () =>
    import(
      './StylableButton.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "StylableButton.corvid" */
    ),
};

export default entry;
