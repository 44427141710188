import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { withValidation } from '@wix/editor-elements-corvid-utils';
import { getColorValidator } from './validation';
import { cssVars } from './constants';
import { getScopedVar } from './styleUtils';

export type IBackgroundColorSDK = {
  backgroundColor: string;
};

export const createBackgroundColorPropsSDKFactory = (options?: {
  prefix?: string;
}) => {
  const cssRule = getScopedVar({
    name: cssVars.backgroundColor,
    prefix: options?.prefix,
  });

  const _backgroundColorPropsSDKFactory: CorvidSDKPropsFactory<
    IBackgroundColorSDK,
    { backgroundColor?: string | null }
  > = ({ setStyles }) => {
    let backgroundColorState: string = '';
    return {
      set backgroundColor(backgroundColor) {
        backgroundColorState = backgroundColor;
        setStyles({
          [cssRule]: backgroundColor,
        });
      },
      get backgroundColor() {
        return backgroundColorState;
      },
    };
  };

  return withValidation(
    _backgroundColorPropsSDKFactory,
    {
      type: ['object'],
      properties: {
        backgroundColor: {
          type: ['string', 'nil'],
        },
      },
    },
    {
      backgroundColor: [getColorValidator('backgroundColor', 'rgbaColor')],
    },
  );
};
