import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
  createStylePropsSDKFactory,
  elementPropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  IFormContainerProps,
  IFormContainerSdkProps,
} from '../FormContainer.types';

export const stylePropsSDKFactory = createStylePropsSDKFactory({
  BackgroundColor: true,
  BorderColor: true,
  BorderWidth: true,
  BorderRadius: true,
});

export const sdk = composeSDKFactories<
  IFormContainerProps,
  IFormContainerSdkProps
>(
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
  stylePropsSDKFactory,
);
