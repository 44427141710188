import { ImpressCorvidModel } from '../Impress.types';

const entry: ImpressCorvidModel = {
  componentType: 'Impress',
  loadSDK: () =>
    import(
      './Impress.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "Impress.corvid" */
    ),
};

export default entry;
