import {
  composeSDKFactories,
  withValidation,
} from '@wix/editor-elements-corvid-utils';
import {
  IStripColumnsContainerOwnSDKFactory,
  IStripColumnsContainerSDK,
  IStripColumnsContainerSDKFactory,
  StripColumnsContainerProps,
} from '../StripColumnsContainer.types';
import { backgroundPropsSDKFactory } from '../../../core/corvid/props-factories/backgroundPropsSDKFactory';
import { elementPropsSDKFactory } from '../../../core/corvid/props-factories/elementPropsSDKFactory';

const _stripColumnsContainerSDKFactory: IStripColumnsContainerOwnSDKFactory = () => {
  return {};
};

export const stripColumnsContainerSDKFactory: IStripColumnsContainerOwnSDKFactory = withValidation(
  _stripColumnsContainerSDKFactory,
  {
    type: ['object'],
    properties: {},
  },
);

export const sdk: IStripColumnsContainerSDKFactory = composeSDKFactories<
  StripColumnsContainerProps,
  IStripColumnsContainerSDK
>(
  elementPropsSDKFactory,
  stripColumnsContainerSDKFactory,
  backgroundPropsSDKFactory,
);
