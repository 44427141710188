import { MasonryCorvidModel } from '../Masonry.types';

const entry: MasonryCorvidModel = {
  componentType: 'Masonry',
  loadSDK: () =>
    import(
      './Masonry.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "Masonry.corvid" */
    ),
};

export default entry;
