import { IPageCorvidModel } from '../Page.types';

const entry: IPageCorvidModel = {
  componentType: 'Page',
  loadSDK: () =>
    import(
      './Page.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "Page.corvid" */
    ),
};

export default entry;
