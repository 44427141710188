import {
  withValidation,
  composeSDKFactories,
  assert,
} from '@wix/editor-elements-corvid-utils';
import {
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  clickPropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  IWRichTextProps,
  IWRichTextSDK,
  IWRichTextOwnSDKFactory,
} from '../WRichText.types';
import {
  wixGuard,
  endBlockTagRegex,
  endTagRegex,
  startTagRegex,
  wixCodeName as type,
} from '../constants';

import {
  applyTransformationForGetHtml,
  applyTransformationForSetHtml,
  convertLinkProperties,
  removeWixGuard,
  decode,
  insertTextInHtml,
  escape,
  unescape,
} from './utils';

const endBlockTagPattern = new RegExp(endBlockTagRegex, 'mg');
const endTagPattern = new RegExp(endTagRegex, 'mg');
const startTagPattern = new RegExp(startTagRegex, 'mg');

export const _wRichTextSdkFactory: IWRichTextOwnSDKFactory = ({
  setProps,
  props,
  linkUtils,
  metaData,
}) => {
  const getHtml = () =>
    removeWixGuard(applyTransformationForGetHtml(props.html));
  const getText = () =>
    props.html
      ? decode(
          unescape(
            removeWixGuard(props.html)
              .replace(/\n/g, '')
              .replace(/<br>/g, '\n')
              .replace(/<br><\/br>/g, '\n')
              .replace(/<br\s*\/?>/g, '\n')
              .replace(endBlockTagPattern, '\n')
              .replace(endTagPattern, '')
              .replace(startTagPattern, '')
              .trim(),
          ),
        )
      : '';

  return {
    get type() {
      return type;
    },

    get html() {
      return getHtml();
    },

    set html(value) {
      const flow = (...fns: Array<Function>) => (html: string) =>
        fns.reduce((output, fn) => fn(output), html);

      const isUndefined = (str: string) => (assert.isNil(str) ? '' : str);

      const convertLink = (str: string) =>
        convertLinkProperties(str, linkUtils.getLinkProps);

      setProps({
        html: flow(
          isUndefined,
          applyTransformationForSetHtml,
          convertLink,
        )(value),
      });
    },

    get text() {
      return getText();
    },

    set text(value) {
      const escapedHTML = value
        ? escape(value).replace(/\n/g, '<br>')
        : wixGuard;

      setProps({ html: insertTextInHtml(props.html, escapedHTML) });
    },

    toJSON() {
      // TODO: use toJSONBase once it's merged
      return {
        id: metaData.compId,
        type,
        global: metaData.isGlobal(),
        rendered: metaData.isRendered(),
        html: getHtml(),
        text: getText(),
      };
    },
  };
};

const wRichTextSDKFactory = withValidation(_wRichTextSdkFactory, {
  type: ['object'],
  properties: {
    html: { type: ['string', 'nil'], warnIfNil: true },
    text: { type: ['string', 'nil'], warnIfNil: true },
  },
});

export const sdk = composeSDKFactories<IWRichTextProps, any, IWRichTextSDK>(
  elementPropsSDKFactory,
  collapsedPropsSDKFactory,
  clickPropsSDKFactory,
  hiddenPropsSDKFactory,
  wRichTextSDKFactory,
);
