import { withValidation } from '@wix/editor-elements-corvid-utils';
import { CorvidTypes } from '@wix/editor-elements-types';
import {
  TpaGalleryCurrentItemSDK,
  TpaGalleryProps,
  OnCurrentItemChangedEvent,
} from '../TPAGallery.types';
import { convertImagesToUserModel } from './TPAGallerySDKUtils';

const _tpaGalleryCurrentItemSDKFactory: CorvidTypes.CorvidSDKFactory<
  TpaGalleryProps,
  TpaGalleryCurrentItemSDK
> = ({ registerEvent, props, linkUtils }) => {
  return {
    get currentItem() {
      const items = props.images;
      if (!items || !this.currentIndex || items.length === 0) {
        return undefined;
      }
      const imageItem = [items[this.currentIndex]];
      const convertedItemToUserModel = convertImagesToUserModel(
        imageItem,
        linkUtils,
      );
      return convertedItemToUserModel[0];
    },
    get currentIndex() {
      return props.currentIndex || 0;
    },
    onCurrentItemChanged(handler) {
      registerEvent(
        'onCurrentItemChanged',
        (event: OnCurrentItemChangedEvent) => {
          const convertedItemToUserModel = convertImagesToUserModel(
            [event.item],
            linkUtils,
          );
          [event.item] = convertedItemToUserModel;
          handler(event);
        },
      );
    },
  };
};

export const tpaGalleryCurrentItemSDKFactory = withValidation(
  _tpaGalleryCurrentItemSDKFactory,
  {
    type: ['object'],
    properties: {
      onCurrentItemChanged: {
        type: ['function'],
        args: [{ type: ['function'] }],
      },
    },
  },
);
