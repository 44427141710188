import { CollageCorvidModel } from '../Collage.types';

const entry: CollageCorvidModel = {
  componentType: 'Collage',
  loadSDK: () =>
    import(
      './Collage.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "Collage.corvid" */
    ),
};

export default entry;
