import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { ISearchBoxProps, ISearchBoxSDK } from '../SearchBox.types';
import {
  elementPropsSDKFactory,
  focusPropsSDKFactory,
  textInputPropsSDKFactory,
  clickPropsSDKFactory,
  disablePropsSDKFactory,
  changePropsSDKFactory,
} from '../../../core/corvid/props-factories';
import { searchBoxValuePropsSDKFactory } from './searchBoxValuePropsSDKFactory';

export const sdk = composeSDKFactories<ISearchBoxProps, ISearchBoxSDK>(
  elementPropsSDKFactory,
  changePropsSDKFactory,
  textInputPropsSDKFactory,
  focusPropsSDKFactory,
  clickPropsSDKFactory,
  disablePropsSDKFactory,
  searchBoxValuePropsSDKFactory,
);
