import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { withValidation } from '@wix/editor-elements-corvid-utils';

type ChangeEventHandler = (e: React.ChangeEvent) => void;

export interface IChangePropsSDKProps {}

export interface IChangePropsSDK {
  onChange: (handler: ChangeEventHandler) => void;
}

export interface IChangePropsSDKActions<T = Element> {
  onChange?: (event: React.ChangeEvent<T>) => void;
}

export const _changePropsSDKFactory: CorvidSDKPropsFactory<
  IChangePropsSDKProps,
  IChangePropsSDK
> = ({ registerEvent }) => {
  return {
    onChange(handler: ChangeEventHandler) {
      registerEvent('onChange', handler);
    },
  };
};

export const changePropsSDKFactory = withValidation(_changePropsSDKFactory, {
  type: ['object'],
  properties: {
    onChange: {
      type: ['function'],
      args: [
        {
          type: ['function'],
        },
      ],
    },
  },
});
