import { ICaptchaCorvidModel } from '../Captcha.types';

const entry: ICaptchaCorvidModel = {
  componentType: 'Captcha',
  loadSDK: () =>
    import(
      './Captcha.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "Captcha.corvid" */
    ),
};

export default entry;
