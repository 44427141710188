import {
  composeSDKFactories,
  withValidation,
} from '@wix/editor-elements-corvid-utils';
import { MouseEventHandler } from 'react';
import {
  ISiteButtonOwnSdk,
  ISiteButtonProps,
  ISiteButtonSDK,
} from '../SiteButton.types';
import {
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  labelPropsSDKFactory,
  linkPropsSDKFactory,
  elementPropsSDKFactory,
  clickPropsSDKFactory,
  createStylePropsSDKFactory,
  collapsedPropsSDKFactory,
} from '../../../core/corvid/props-factories';

const _SiteButtonSDKFactory: ISiteButtonOwnSdk = api => {
  const { setProps, props, registerEvent } = api;

  return {
    onClick: (handler: MouseEventHandler) => {
      registerEvent('onClick', handler);
      if (!props.hasPlatformClickHandler) {
        setProps({
          hasPlatformClickHandler: true,
        });
      }
    },
  };
};

const SiteButtonSDKFactory = withValidation(_SiteButtonSDKFactory, {
  type: ['object'],
  properties: {
    onClick: {
      type: ['function'],
      args: [{ type: ['function'] }],
    },
  },
});

const stylePropsSDKFactory = createStylePropsSDKFactory({
  BackgroundColor: true,
  BorderColor: true,
  BorderWidth: true,
  BorderRadius: true,
  TextColor: true,
});

export const sdk = composeSDKFactories<ISiteButtonProps, ISiteButtonSDK>(
  clickPropsSDKFactory,
  labelPropsSDKFactory,
  collapsedPropsSDKFactory,
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  linkPropsSDKFactory,
  elementPropsSDKFactory,
  stylePropsSDKFactory,
  SiteButtonSDKFactory,
);
