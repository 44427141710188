import { HoneycombCorvidModel } from '../Honeycomb.types';

const entry: HoneycombCorvidModel = {
  componentType: 'Honeycomb',
  loadSDK: () =>
    import(
      './Honeycomb.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "Honeycomb.corvid" */
    ),
};

export default entry;
