import { IMusicPlayerCorvidModel } from '../MusicPlayer.types';

const entry: IMusicPlayerCorvidModel = {
  componentType: 'MusicPlayer',
  loadSDK: () =>
    import(
      './MusicPlayer.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "MusicPlayer.corvid" */
    ),
};

export default entry;
