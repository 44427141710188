import { ITinyMenuCorvidModel } from '../TinyMenu.types';

const entry: ITinyMenuCorvidModel = {
  componentType: 'TinyMenu',
  loadSDK: () =>
    import(
      './TinyMenu.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "TinyMenu.corvid" */
    ),
};

export default entry;
