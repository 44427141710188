import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';

import {
  IHtmlComponentSDKFactory,
  IHtmlComponentProps,
  IHtmlComponentSDK,
} from '../HtmlComponent.types';
import {
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
} from '../../../core/corvid/props-factories';

export const _htmlComponentSdkFactory: IHtmlComponentSDKFactory = ({
  setProps,
  setStyles,
  registerEvent,
  props,
  compRef,
  metaData,
}) => {
  const _state = {
    hidden: metaData.hiddenOnLoad,
  };
  return {
    get scrolling() {
      return props.scrolling;
    },

    set scrolling(value) {
      setProps({ scrolling: value });
    },

    get src() {
      return props.url;
    },
    set src(value) {
      setProps({ url: value });
    },

    allowFullScreen: () => setProps({ allow: 'fullscreen' }),

    onMessage: cb => registerEvent('onMessage', cb),

    postMessage: message => compRef.postMessage(message),

    /**
     * This here below is reimplementation of hiddenMixin
     * because iframe does not play well with visibility css rule
     */
    hide() {
      setStyles({ visibility: 'hidden', '--corvid-hidden-display': 'none' });
      _state.hidden = true;
      return Promise.resolve();
    },

    show() {
      setStyles({ visibility: null, '--corvid-hidden-display': null });
      _state.hidden = false;
      return Promise.resolve();
    },

    get hidden() {
      return Boolean(_state.hidden);
    },
  };
};

const htmlComponentSdkFactory = withValidation(_htmlComponentSdkFactory, {
  type: ['object'],
  properties: {
    src: { type: ['string'] },
    scrolling: { type: ['string'], enum: ['yes', 'no', 'auto'] },
    allowFullScreen: { type: ['function'] },
    onMessage: { type: ['function'], args: [{ type: ['function'] }] },
    postMessage: { type: ['function'], args: [{ type: ['string'] }] },
  },
});

export const sdk = composeSDKFactories<IHtmlComponentProps, IHtmlComponentSDK>(
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  htmlComponentSdkFactory,
);
