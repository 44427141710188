import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';

export interface ICollapsedSDKProps {
  collapse: () => Promise<void>;
  expand: () => Promise<void>;
  collapsed: boolean;
}

export const collapsedPropsSDKFactory: CorvidSDKPropsFactory<
  ICollapsedSDKProps,
  {}
> = ({ setStyles, metaData }) => {
  const _state = {
    collapsed: metaData.collapsedOnLoad,
  };
  return {
    collapse() {
      if (!_state.collapsed) {
        setStyles({
          visibility: 'hidden !important',
          height: '0 !important',
          width: '0 !important',
          'min-width': '0 !important',
          overflow: 'hidden !important',
          'min-height': '0 !important',
          'margin-bottom': '0 !important',
        });
        _state.collapsed = true;
      }
      return Promise.resolve();
    },
    expand() {
      if (_state.collapsed) {
        setStyles({
          visibility: null,
          height: null,
          width: null,
          'min-width': null,
          overflow: null,
          'min-height': null,
          'margin-bottom': null,
        });

        _state.collapsed = false;
      }
      return Promise.resolve();
    },
    get collapsed() {
      return _state.collapsed;
    },
  };
};
