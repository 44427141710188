import {
  CorvidSDKPropsFactory,
  CorvidSDKFactory,
} from '@wix/editor-elements-types';
import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import {
  IBackgroundColorSDK,
  IBorderColorSDK,
  IForegroundColorSDK,
  IBorderRadiusSDK,
  ITextColorSDK,
  IBorderWidthSDK,
  createBackgroundColorPropsSDKFactory,
  createBorderColorPropsSDKFactory,
  createBorderRadiusPropsSDKFactory,
  createBorderWidthPropsSDKFactory,
  createForegroundColorPropsSDKFactory,
  createTextColorPropsSDKFactory,
} from './styleSDKFactories';

type StyleProps = IBackgroundColorSDK &
  IBorderColorSDK &
  IForegroundColorSDK &
  IBorderRadiusSDK &
  ITextColorSDK &
  IBorderWidthSDK;

export interface IStylePropSDK {
  style: Partial<StyleProps>;
}

export type StyleSDKOptions = {
  cssVarPrefix?: string;
};

const _stylePropsSDKFactory = (
  supportedSDKFactories: CorvidSDKFactory<object, object, any, any>,
): CorvidSDKPropsFactory<any, IStylePropSDK> => api => {
  const styleSDKs = supportedSDKFactories(api);
  return {
    get style() {
      return styleSDKs;
    },
  };
};

type IStyleCSSProperty =
  | 'BackgroundColor'
  | 'BorderColor'
  | 'BorderWidth'
  | 'ForegroundColor'
  | 'BorderRadius'
  | 'TextColor';

const styleFactories = {
  BackgroundColor: createBackgroundColorPropsSDKFactory,
  BorderColor: createBorderColorPropsSDKFactory,
  BorderWidth: createBorderWidthPropsSDKFactory,
  ForegroundColor: createForegroundColorPropsSDKFactory,
  BorderRadius: createBorderRadiusPropsSDKFactory,
  TextColor: createTextColorPropsSDKFactory,
} as const;

export const createStylePropsSDKFactory = (
  list: Partial<Record<IStyleCSSProperty, boolean>>,
  styleSDKOptions?: StyleSDKOptions,
) => {
  const supported = (Object.keys(list) as Array<IStyleCSSProperty>).filter(
    value => list[value],
  );

  const supportedSDKFactories = supported.map(value =>
    styleFactories[value]({ prefix: styleSDKOptions?.cssVarPrefix }),
  );

  return withValidation(
    _stylePropsSDKFactory(composeSDKFactories(...supportedSDKFactories)),
    {
      type: ['object'],
      properties: {
        style: {
          type: ['object'],
        },
      },
    },
  );
};
