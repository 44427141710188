import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import {
  IQuickActionBarSDKFactory,
  IQuickActionBarProps,
  IQuickActionBarSDK,
} from '../QuickActionBar.types';
import {
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
} from '../../../core/corvid/props-factories';

const _quickActionBarSDKFactory: IQuickActionBarSDKFactory = ({
  setProps,
  props,
  registerEvent,
}) => ({
  get alignment() {
    return props.alignment;
  },
  set alignment(value) {
    setProps({ alignment: value });
  },
  get colorScheme() {
    return props.colorScheme;
  },
  set colorScheme(value) {
    setProps({ colorScheme: value });
  },
  get invertColorScheme() {
    return props.isColorSchemeInverted;
  },
  set invertColorScheme(shouldInvert) {
    setProps({ isColorSchemeInverted: shouldInvert });
  },
  get showLabels() {
    return !props.hideText;
  },
  set showLabels(shouldShow) {
    setProps({ hideText: !shouldShow });
  },
  onItemClicked(cb) {
    registerEvent('onQuickActionBarItemClicked', cb);
  },
});

const quickActionBarSDKFactory = withValidation(_quickActionBarSDKFactory, {
  type: ['object'],
  properties: {
    showLabels: {
      type: ['boolean'],
    },
    alignment: {
      type: ['string'],
      enum: ['left', 'right'],
    },
    colorScheme: {
      type: ['string'],
      enum: ['brand', 'grey', 'black'],
    },
    invertColorScheme: {
      type: ['boolean'],
    },
    onItemClicked: {
      type: ['function'],
    },
  },
});

export const sdk = composeSDKFactories<
  IQuickActionBarProps,
  any,
  IQuickActionBarSDK
>(quickActionBarSDKFactory, hiddenPropsSDKFactory, collapsedPropsSDKFactory);
