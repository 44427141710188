import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
} from '../props-factories';
import { IFallbackProps, IFallbackSDK } from './Fallback.types';

export const sdk = composeSDKFactories<IFallbackProps, IFallbackSDK, any>(
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
);
