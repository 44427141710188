import { IComboBoxInputCorvidModel } from '../ComboBoxInput.types';

const entry: IComboBoxInputCorvidModel = {
  componentType: 'ComboBoxInput',
  loadSDK: () =>
    import(
      './ComboBoxInput.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "ComboBoxInput.corvid" */
    ),
};

export default entry;
