import { ValidationData } from './inputValidationTypes';
import { addErrorToValidationDataAndKeepMessage } from './validityUtils';

export const validateRequiredSelectedDate = (
  props: {
    required: boolean;
    value?: Date;
  },
  validationData: ValidationData,
): ValidationData => {
  if (!props.required || props.value) {
    return validationData;
  }

  return addErrorToValidationDataAndKeepMessage(validationData, 'valueMissing');
};
