import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { withValidation } from '@wix/editor-elements-corvid-utils';
import { getPixelValidator } from './validation';
import { getScopedVar } from './styleUtils';
import { cssVars } from './constants';

export type IBorderRadiusSDK = {
  borderRadius: string;
};

export const createBorderRadiusPropsSDKFactory = (options?: {
  prefix?: string;
}) => {
  const cssRule = getScopedVar({
    name: cssVars.borderRadius,
    prefix: options?.prefix,
  });

  const _borderRadiusPropsSDKFactory: CorvidSDKPropsFactory<
    IBorderRadiusSDK,
    { borderRadius?: string | null }
  > = ({ setStyles }) => {
    let borderRadiusState: string = '';
    return {
      set borderRadius(borderRadius) {
        borderRadiusState = borderRadius;
        setStyles({
          [cssRule]: borderRadius,
        });
      },
      get borderRadius() {
        return borderRadiusState;
      },
    };
  };
  return withValidation(
    _borderRadiusPropsSDKFactory,
    {
      type: ['object'],
      properties: {
        borderRadius: {
          type: ['string', 'nil'],
        },
      },
    },
    {
      borderRadius: [getPixelValidator('borderRadius', 'radius')],
    },
  );
};
