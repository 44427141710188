import { FreestyleCorvidModel } from '../Freestyle.types';

const entry: FreestyleCorvidModel = {
  componentType: 'Freestyle',
  loadSDK: () =>
    import(
      './Freestyle.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "Freestyle.corvid" */
    ),
};

export default entry;
