import { IImageXCorvidModel } from '../ImageX.types';

const entry: IImageXCorvidModel = {
  componentType: 'ImageX',
  loadSDK: () =>
    import(
      './ImageX.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "ImageX.corvid" */
    ),
};

export default entry;
