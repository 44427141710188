import { IWPhotoCorvidModel } from '../WPhoto.types';

const entry: IWPhotoCorvidModel = {
  componentType: 'WPhoto',
  loadSDK: () =>
    import(
      './WPhoto.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "WPhoto.corvid" */
    ),
};

export default entry;
