import { IVideoLegacyCorvidModel } from '../VideoLegacy.types';

const entry: IVideoLegacyCorvidModel = {
  componentType: 'Video',
  loadSDK: () =>
    import(
      './VideoLegacy.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "VideoLegacy.corvid" */
    ),
};

export default entry;
