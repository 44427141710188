import { MouseEventHandler } from 'react';
import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { basePropsSDKFactory } from './basePropsSDKFactory';
import { viewportPropsSDKFactory } from './viewportSDKFactory';

export interface IElementPropsSDKProps {}

export type ToJSONBase = {
  id: string;
  type: string;
  global: boolean;
  rendered: boolean;
};

export interface IElementPropsSDK {
  onMouseIn: (cb: MouseEventHandler) => void;
  onMouseOut: (cb: MouseEventHandler) => void;
  toJSON(): ToJSONBase;
  rendered: boolean;
}
export interface IElementPropsSDKActions {
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
}

export const toJSONBase = ({
  role,
  compType,
  isGlobal,
  isRendered,
}: {
  role: string;
  compType: string;
  isGlobal: () => boolean;
  isRendered: () => boolean;
}): ToJSONBase => ({
  id: role,
  type: `$w.${compType}`,
  global: isGlobal(),
  rendered: isRendered(),
});

export const _elementPropsSDKFactory: CorvidSDKPropsFactory<
  IElementPropsSDKProps,
  IElementPropsSDK
> = ({ registerEvent, metaData }) => ({
  onMouseIn(cb: MouseEventHandler) {
    registerEvent('onMouseEnter', cb);
  },

  onMouseOut(cb: MouseEventHandler) {
    registerEvent('onMouseLeave', cb);
  },

  get rendered() {
    return metaData.isRendered();
  },

  toJSON() {
    return toJSONBase(metaData);
  },
});

export const elementPropsSDKFactory = composeSDKFactories(
  basePropsSDKFactory,
  viewportPropsSDKFactory,
  withValidation(_elementPropsSDKFactory, {
    type: ['object'],
    properties: {
      onMouseIn: {
        type: ['function'],
        args: [
          {
            type: ['function'],
          },
        ],
      },
      onMouseOut: {
        type: ['function'],
        args: [
          {
            type: ['function'],
          },
        ],
      },
    },
  }),
);
