import { IWRichTextCorvidModel } from '../WRichText.types';

const entry: IWRichTextCorvidModel = {
  componentType: 'WRichText',
  loadSDK: () =>
    import(
      './WRichText.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "WRichText.corvid" */
    ),
};

export default entry;
