import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { withValidation } from '@wix/editor-elements-corvid-utils';
import { getColorValidator } from './validation';
import { getScopedVar } from './styleUtils';
import { cssVars } from './constants';

export type IBorderColorSDK = {
  borderColor: string;
};

export const createBorderColorPropsSDKFactory = (options?: {
  prefix?: string;
}) => {
  const cssRule = getScopedVar({
    name: cssVars.borderColor,
    prefix: options?.prefix,
  });

  const _borderColorPropsSDKFactory: CorvidSDKPropsFactory<
    IBorderColorSDK,
    { borderColor?: string | null }
  > = ({ setStyles }) => {
    let borderColorState: string = '';
    return {
      set borderColor(borderColor) {
        borderColorState = borderColor;
        setStyles({
          [cssRule]: borderColor,
        });
      },
      get borderColor() {
        return borderColorState;
      },
    };
  };

  return withValidation(
    _borderColorPropsSDKFactory,
    {
      type: ['object'],
      properties: {
        borderColor: {
          type: ['string', 'nil'],
        },
      },
    },
    {
      borderColor: [getColorValidator('borderColor', 'rgbaColor')],
    },
  );
};
