import { ICorvidModel } from '@wix/editor-elements-types';

const entry: ICorvidModel = {
  componentType: 'VectorImage',
  loadSDK: () =>
    import(
      './VectorImage.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "VectorImage.corvid" */
    ),
};

export default entry;
