import { ISiteButtonCorvidModel } from '../SiteButton.types';

const entry: ISiteButtonCorvidModel = {
  componentType: 'SiteButton',
  loadSDK: () =>
    import(
      './SiteButton.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "SiteButton.corvid" */
    ),
};

export default entry;
