import { IHeaderContainerCorvidModel } from '../HeaderContainer.types';

const entry: IHeaderContainerCorvidModel = {
  componentType: 'HeaderContainer',
  loadSDK: () =>
    import(
      './HeaderContainer.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "HeaderContainer.corvid" */
    ),
};

export default entry;
