import { ICheckboxCorvidModel } from '../Checkbox.types';

const entry: ICheckboxCorvidModel = {
  componentType: 'Checkbox',
  loadSDK: () =>
    import(
      './Checkbox.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "Checkbox.corvid" */
    ),
};

export default entry;
