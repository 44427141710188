import { IDatePickerCorvidModel } from '../DatePicker.types';

const entry: IDatePickerCorvidModel = {
  componentType: 'DatePicker',
  loadSDK: () =>
    import(
      './DatePicker.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "DatePicker.corvid" */
    ),
};

export default entry;
