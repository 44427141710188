import { ThumbnailsCorvidModel } from '../Thumbnails.types';

const entry: ThumbnailsCorvidModel = {
  componentType: 'Thumbnails',
  loadSDK: () =>
    import(
      './Thumbnails.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "Thumbnails.corvid" */
    ),
};

export default entry;
